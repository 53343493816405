function Rodape(parametros) {

    if (parametros.aberto) {
        return (
            <></>
        );
    }

	const languageStrings = {
		"pt-br": {
            "sobre": "A EVE",
            "contato": "CONTATO",
		},
		"en-us": {
            "sobre": "ABOUT EVE",
            "contato": "CONTACT",
		}
	}

	return (
        <footer className="darkblue-background page-block">
            <div className="eve_fotter_logo_parent">
                <img className="eve_fotter_logo" src="../images/eve.webp" alt="Eve" />
            </div>
            <div
                className="flexCol footer_links_parent"
                style={{ padding: 0, margin: 0 }}
            >
                <div className="footer_links">
                    <a href="/">HOME</a>
                    <a href="#about">{languageStrings[parametros.language].sobre}</a>
                    <a href="#founders">FOUNDERS</a>
                    <a href="#contact">{languageStrings[parametros.language].contato}</a>
                </div>
                <p
                    className="footer_cc"
                    style={{
                        width: "100%",
                        textAlign: "center",
                        fontFamily: "Sans-serif",
                        fontWeight: "lighter"
                    }}
                >
                    © 2022 Project Eve
                </p>
            </div>
            <div className="footer_share_parent">
                <div
                    style={{
                        width: "55%",
                        height: "auto",
                        margin: "10% 20% 0px",
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}
                >
                    <div style={{ marginRight: "10px" }}><a href="https://discord.gg/5FTKfTzf4v" className="fab fa-discord"> </a></div>
                    <div style={{ marginRight: "10px" }}><a href="https://twitter.com/NFTEVE_" className="fab fa-twitter"> </a></div>
                    <div style={{ marginRight: "10px" }}><a href="https://www.linkedin.com/company/eve-nft/" className="fab fa-linkedin"> </a></div>
                    <div style={{ marginRight: "10px" }}><a href="https://www.instagram.com/eve.nft/" className="fab fa-instagram"> </a></div>
                    <div style={{ marginRight: "10px" }}><a href="https://opensea.io/assets/ethereum/0xe454b857d2eaf8895f8c6c40319637a1581519e9/0" target="_blank" className="fa-opensea"></a></div>
                    <div><a href="https://etherscan.io/address/0xe454b857d2eaf8895f8c6c40319637a1581519e9" className="fa-etherscan" target="_blank"></a></div>
                </div>
            </div>
        </footer>
		);
}

export default Rodape;