import { Link } from "react-router-dom";

function Topo(parametros) {

    if (parametros.exibirBloco === false) {
        return (
            <></>
        );
    }

    const languageStrings = {
        "pt-br": {
            "titulo": "Empoderando Mulheres",
            "subtitulo": "Para estarem a frente da Web 3.0 por meio de uma comunidade apoiada por todos.",
            "fazer_parte": "QUERO FAZER PARTE DA COMUNIDADE",
            "bandeira": "../images/flags/eua.svg",
            "link": "/en-us",
        },
        "en-us": {
            "titulo": "Empowering Women",
            "subtitulo": "To be a vanguard of WEB 3.0 supported by EVERYONE",
            "fazer_parte": "BE PART OF THE COMMUNITY",
            "bandeira": "../images/flags/bra.svg",
            "link": "/pt-br",
        }
    }

	return (
        <section className="maingBackground">
            <div className="flexCol mainInfo">
                <img
                    src="../images/eve_logo.webp"
                    style={{ margin: "2em 0 1em" }}
                    width="35%"
                />
                <h1 className="title">{languageStrings[parametros.language].titulo}</h1>
                <p className="participate_text">{languageStrings[parametros.language].subtitulo}</p>
                <button id="button_participate" className="mint-button" style={{ display: parametros.mostrarBotaoComunidade?"none":"block" }}>
                    <a href="#contact">{languageStrings[parametros.language].fazer_parte}</a>
                </button>
                <div
                    style={{
                        marginTop: "10em",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "80%"
                    }}
                >
                    <a href="https://discord.gg/5FTKfTzf4v" className="fab fa-discord" />
                    <a href="https://twitter.com/NFTEVE_" className="fab fa-twitter" />
                    <a
                        href="https://www.linkedin.com/company/eve-nft/"
                        className="fab fa-linkedin"
                    />
                    <a
                        href="https://www.instagram.com/eve.nft/"
                        className="fab fa-instagram"
                    />
                    <a className="fa-opensea" href="https://opensea.io/assets/ethereum/0xe454b857d2eaf8895f8c6c40319637a1581519e9/0" target="_blank" className="fa-opensea"></a>
                    <a className="fa-etherscan" href="https://etherscan.io/address/0xe454b857d2eaf8895f8c6c40319637a1581519e9" className="fa-etherscan" target="_blank"></a>
                </div>
            </div>
            <img className="particleBackground" src="../images/particulas.webp" />
            <img className="mainImage" style={{ zIndex: 0 }} src="../images/front.webp" />
            <Link
                style={{ position: "absolute", left: 10, top: 10, zIndex: 1 }}
                to={languageStrings[parametros.language].link}
            >
                <img
                    style={{
                        width: "calc(1.5em + 1vmin + 1vmax)",
                        height: "auto",
                    }}
                    src={languageStrings[parametros.language].bandeira}
                />
            </Link>
        </section>
		);
}

export default Topo;